<template>
  <div>
    <el-page-header @back="dynamic" content="信息发布" style="margin-bottom: 15px;">
    </el-page-header>
    <el-form ref="form" :model="editForm" label-width="80px" size="mini" label-position="top">
      <el-form-item>
        <el-input v-model="editForm.newsTitle" placeholder="请输入标题"></el-input>
      </el-form-item>
      <el-form-item class="quill-wrap">
        <quill-editor
            ref="myQuillEditor"
            v-model="editForm.news"
            class="myQuillEditor"
            :options="editorOption" />
      </el-form-item>
      <el-form-item>
        <div  style="margin-top: 100px;">
          <el-select v-model="editForm.modular" placeholder="模块" clearable style="width: 100px;margin-right: 20px">
            <el-option
                v-for="item in dictionaryData"
                :key="item.value"
                :label="item.dictionaryName"
                :value="item.value">
              <span>{{ item.dictionaryName }}</span>
            </el-option>
          </el-select>
          <el-radio-group v-model="editForm.isOpen">
            <el-radio-button :label="0">私有</el-radio-button>
            <el-radio-button :label="1">公开</el-radio-button>
          </el-radio-group>
          <el-input-number v-model="editForm.newsIndex" :min="1" :max="38" label="排序" style="width: 100px ;margin-left: 10px"></el-input-number>
<!--          <el-select v-model="editForm.newsType" placeholder="排序" clearable style="width: 100px;margin-left: 20px">-->
<!--            <el-option label="人物简介" :value="1"></el-option>-->
<!--            <el-option label="新闻动态" :value="0"></el-option>-->
<!--          </el-select>-->
          <el-button type="primary" @click="submit" size="mini" style="margin-left: 15px">立即发布</el-button>

        </div>
      </el-form-item>
    </el-form>
    <div style="font-size: small; padding-left: 20px; color: red">
        <span>注意：1、排序前5可显示在首页上，超过5在更多中体现。排序序号有相同的则会随机排序并会挤掉最后排序序号的文章。</span>
        <br />
        <span style="padding-left: 40px">2、点击私有，文章不会发布在前台页面，可以在后台查找并编辑，功能等同于“草稿箱”</span>
    </div>
  </div>
</template>

<script>
import {quillEditor, Quill} from 'vue-quill-editor'
import {container, ImageExtend, QuillWatch} from 'quill-image-extend-module'
import imageResize from 'quill-image-resize-module';
Quill.register('modules/imageResize', imageResize)
Quill.register('modules/ImageExtend', ImageExtend)
export default {
  name: "editer",
  components: {
    quillEditor
  },
  data() {
    return {
      content: '',
      editForm: {
        newsId: '',
        newsTitle: '',
        news: '',
        modular: '',
        isOpen: '1',
        newsType: '',
        newsIndex: '10'
      },
      dictionaryData: [],
      editorOption: {
        modules: {
          imageResize: {},
          ImageExtend: {  // 如果不作设置，即{}  则依然开启复制粘贴功能且以base64插入
            name: 'img',  // 图片参数名
            // size: 3,  // 可选参数 图片大小，单位为M，1M = 1024kb
            action: '/api/news/uploadImg',  // 服务器地址, 如果action为空，则采用base64插入图片
            // response 为一个函数用来获取服务器返回的具体图片地址
            // 例如服务器返回{code: 200; data:{ url: 'baidu.com'}}
            // 则 return res.data.url
            response: (res) => {
              return res.msg;
            },
            // headers: (xhr) => {
            //   xhr.setRequestHeader('myHeader','myValue')
            // },  // 可选参数 设置请求头部
            sizeError: () => {},  // 图片超过大小的回调
            // start: () => {},  // 可选参数 自定义开始上传触发事件
            // end: () => {},  // 可选参数 自定义上传结束触发的事件，无论成功或者失败
            // error: () => {},  // 可选参数 上传失败触发的事件
            // success: () => {},  // 可选参数  上传成功触发的事件
            // change: (xhr, formData) => {
            //   xhr.setRequestHeader('myHeader','myValue')
            //   formData.append('token', 'myToken')
            // } // 可选参数 每次选择图片触发，也可用来设置头部，但比headers多了一个参数，可设置formData
          },
          toolbar: {  // 如果不上传图片到服务器，此处不必配置
            container: container,  // container为工具栏，此次引入了全部工具栏，也可自行配置
            handlers: {
              'image': function () {  // 劫持原来的图片点击按钮事件
                QuillWatch.emit(this.quill.id)
              }
            }
          }
        }
      }
    }
  },
  mounted() {
    this.getEditDictionaryByModular(2)
    if (this.$route.query.newsId !== undefined) {
      this.getNewsById(this.$route.query.newsId)
    }
  },
  methods: {
    getEditDictionaryByModular (editModular) {
      this.$http({
        method: 'get',
        url: '/api/dictionary/getDictionaryByModular/' + editModular
      }).then((res) =>{
        this.dictionaryData =res.data.data
      })
    },
    dynamic() {
      this.$router.push("/release")
    },
    getNewsById(newsId) {
      this.$http({
        method: 'get',
        url: '/api/news/getNewsById/' + newsId,
      }).then((res) =>{
        this.editForm = res.data.data
      })
    },
    submit () {
      var msgNews = '新增一篇新闻？';
      var newsMethod = 'post';
      var newsUrl = '/api/news/insertNews';
      if (this.editForm.newsId !== '') {
        msgNews = '是否修改此条新闻？';
        newsMethod = 'put';
        newsUrl = '/api/news/editNews';
      }
      this.$confirm(msgNews, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          method: newsMethod,
          url: newsUrl,
          data: this.editForm
        }).then((res) => {
          this.$message({
            type: 'success',
            message: res.data.msg
          })
          this.$router.push("/release")
        })
      })
    }
  }
}
</script>

<style scoped>
.w-50 {
  width: 50%;
  margin-left: 18%;
}
.myQuillEditor {
  height: 600px;
}
</style>